import FollowupItemDialogController from 'core/prf/controllers/followup-item-dialog.controller';

export default class FollowupInjuryDialogController extends FollowupItemDialogController {
  init () {
    super.init();

    this.relationData.bodyParts = this.relationData.bodyParts.filter(item => {
      return item.injuries.length > 0;
    });
  }
}
