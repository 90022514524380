import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class IllnessDialogController extends ResourceDialogController {
  init () {
    super.init();

    this.relationData.bodyParts = this.relationData.bodyParts.filter(item => {
      return item.illnesses.length > 0;
    });

    this.addAnother = false;

    if (!this.item) {
      this.item = {
        status: 'red',
      };
    }

    const $scope = this.$injector.get('$rootScope').$new();
    this.illnesses = [];
    $scope.$watch(() => this.item.body_part, (newVal) => {
      if (!newVal) return;
      this.illnesses = newVal.illnesses.filter(i => !i.followup_only);
    });
  }

  submit (item, addAnother) {
    if (!this.form) {
      throw new Error('Form not found.');
    }

    if (this.form.$invalid) {
      return;
    }

    if (!item.id) {
      item.id = uuid.v4();
    }
    return this.Dialog.hide({ item, addAnother });
  };

  delete (id) {
    this.Dialog.confirm().then(() => {
      this.Dialog.cancel(id);
    });
  }
}
