import angular from 'angular';
import HospitalInformationDialog from './services/hospital-information-dialog/hospital-information-dialog.service';
import IllnessDialog from './services/illness-dialog/illness-dialog.service';
import InjuryDialog from './services/injury-dialog/injury-dialog.service';
import InterventionDialog from './services/intervention-dialog/intervention-dialog.service';
import LaboratoryFindingsDialog from './services/laboratory-findings-dialog/laboratory-findings-dialog.service';
import Observations from './components/observations/observations.component';
import PrescribedDrugs from './components/prescribed-drugs/prescribed-drugs.component';
import AdministeredDrugs from './components/administered-drugs/administered-drugs.component';
import PatientDetails from './components/patient-details/patient-details.component';
import PatientDetailsDialog from './services/patient-details-dialog/patient-details-dialog.service';
import PRFListItem from './components/prf-list-item/prf-list-item.component';
import PRFApprovalDialog from './services/prf-approval-dialog/prf-approval-dialog.service';
import PRFRejectionDialog from './services/prf-rejection-dialog/prf-rejection-dialog.service';
import PRFResource from './resources/prf.resource';
import PrintablePRFResource from './resources/printable-prf.resource';
import SurveyItemDetails from './components/survey-item-details/survey-item-details.component';
import SurveyInterventionDetails from './components/survey-intervention-details/survey-intervention-details.component';
import SurveyLaboratoryFindingsDetails from './components/survey-laboratory-findings-details/survey-laboratory-findings-details.component';
import SurveyResource from './resources/survey.resource';
import SurveySwitcher from './components/survey-switcher/survey-switcher.component';
import RecordDrugUseDialog from './services/record-drug-use-dialog/record-drug-use-dialog.service';
import ReviewDrugUseDialog from './services/review-drug-use-dialog/review-drug-use-dialog.service';
import ReviewQpiDialog from './services/review-qpi-dialog/review-qpi-dialog.service';
import AdministeredInfusions from './components/administered-infusions/administered-infusions.component';
import PrescribedInfusions from './components/prescribed-infusions/prescribed-infusions.component';
import FollowupResource from './resources/followup.resource';
import FollowupSwitcher from './components/followup-switcher/followup-switcher.component';
import FollowupIllnessDialog from './services/followup-illness-dialog/followup-illness-dialog.service';
import FollowupInjuryDialog from './services/followup-injury-dialog/followup-injury-dialog.service';
import FollowupItemDetails from './components/followup-item-details/followup-item-details.component';

const module = angular
  .module('gnaas.core.prf', [])
  .component('prescribedDrugs', PrescribedDrugs)
  .component('administeredDrugs', AdministeredDrugs)
  .component('prescribedInfusions', PrescribedInfusions)
  .component('administeredInfusions', AdministeredInfusions)
  .component('observations', Observations)
  .component('patientDetails', PatientDetails)
  .component('prfListItem', PRFListItem)
  .component('surveyItemDetails', SurveyItemDetails)
  .component('surveyInterventionDetails', SurveyInterventionDetails)
  .component('surveyLaboratoryFindingsDetails', SurveyLaboratoryFindingsDetails)
  .component('surveySwitcher', SurveySwitcher)
  .component('followupItemDetails', FollowupItemDetails)
  .component('followupSwitcher', FollowupSwitcher)
  .service('HospitalInformationDialog', HospitalInformationDialog)
  .service('IllnessDialog', IllnessDialog)
  .service('InjuryDialog', InjuryDialog)
  .service('InterventionDialog', InterventionDialog)
  .service('LaboratoryFindingsDialog', LaboratoryFindingsDialog)
  .service('PatientDetailsDialog', PatientDetailsDialog)
  .service('PRFApprovalDialog', PRFApprovalDialog)
  .service('PRFRejectionDialog', PRFRejectionDialog)
  .service('PRFResource', PRFResource)
  .service('PrintablePRFResource', PrintablePRFResource)
  .service('SurveyResource', SurveyResource)
  .service('RecordDrugUseDialog', RecordDrugUseDialog)
  .service('ReviewDrugUseDialog', ReviewDrugUseDialog)
  .service('ReviewQpiDialog', ReviewQpiDialog)
  .service('FollowupResource', FollowupResource)
  .service('FollowupIllnessDialog', FollowupIllnessDialog)
  .service('FollowupInjuryDialog', FollowupInjuryDialog);

export default module.name;
