import CreateQualityIndicatorController from './createQualityIndicatorController';

export default function QualityIndicatorResourceController (QualityIndicatorResource) {
  'ngInject';

  this.singularName = 'Quality Performance Indicator';
  this.pluralName = 'Quality Performance Indicators';

  this.createDialogController = CreateQualityIndicatorController;
  this.createDialogTemplateUrl = 'pages/admin/quality-indicators/templates/createDialog.tpl.html';

  QualityIndicatorResource
    .index()
    .then(items => {
      this.items = items.map(item => {
        return item;
      });
    });

  this.relationData = { };

  this.listFields = {
    name: 'Name',
  };

  this.usesSidenav = false;
}
