import ResourceDialog from 'common/resource/services/resourceDialog';
import InfusionDialogController from './infusion-dialog.controller';

export default class InfusionDialog extends ResourceDialog {
  /**
   * @ngInject
   */

  init () {
    super.init();

    this.setController(InfusionDialogController);
    this.setTemplateUrl('pages/admin/drugs/services/infusion-dialog/infusion-dialog.tpl.html');
  }
}
