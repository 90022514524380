import angular from 'angular';
import uiRouter from 'angular-ui-router';
import QualityIndicatorResourceController from './controllers/qualityIndicatorResourceController';
import QualityIndicatorResource from './services/qualityIndicatorResource';

const moduleConfig = function ($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.qualityIndicators', {
    url: '/quality-indicators',
    controller: QualityIndicatorResourceController,
    controllerAs: '$ctrl',
    templateUrl: 'common/resource/templates/resource.tpl.html',
    data: {
      title: 'Quality Performance Indicators',
    },
  });
};

const module = angular
  .module('gnaas.pages.admin.qualityIndicators', [
    uiRouter,
  ])
  .config(moduleConfig)
  .service('QualityIndicatorResource', QualityIndicatorResource);

export default module.name;
