import CreateInfusionController from '../services/infusion-dialog/infusion-dialog.controller';
import { RESOURCE_ITEM_FIELD_TYPES } from 'common/resource/module.constants';

const DEFAULT_LIST_FIELDS = {
  name: 'Name',
  doseWithUnit: 'Dose',
  volume: 'Volume (ml)',
  'solution.name': 'Solution',
};

const DEFAULT_PROFILE_FIELDS = [
  {
    key: 'dose',
    label: 'Dose',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'measurement_type',
    label: 'Measurement Type',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'volume',
    label: 'Volume (ml)',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'min_rate',
    label: 'Min Rate (ml/hr)',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'max_rate',
    label: 'Max Rate (ml/hr)',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'routes',
    collectionKey: 'name',
    label: 'Routes',
    type: RESOURCE_ITEM_FIELD_TYPES.collection,
  },
  {
    key: 'solution.name',
    label: 'Solution',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
];

export default function InfusionListController (
  InfusionResource, InfusionSolutionResource, MedicationRouteResource
) {
  'ngInject';

  this.singularName = 'Infusion';
  this.pluralName = 'Infusions';

  this.createDialogController = CreateInfusionController;
  this.createDialogTemplateUrl = 'pages/admin/drugs/services/infusion-dialog/infusion-dialog.tpl.html';

  InfusionResource
    .index()
    .then(items => {
      this.items = items.map(item => ({
        ...item,
        doseWithUnit: item.dose ? item.dose + item.measurement_type : null
      }));
    });

  this.relationData = { measurementTypes: [], solutions: [], routes: [] };
  InfusionResource.getMeasurementTypes().then(items => { this.relationData.measurementTypes = items; });
  InfusionSolutionResource.index().then(items => { this.relationData.solutions = items; });
  MedicationRouteResource.index().then(items => { this.relationData.routes = items; });

  this.hasProfiles = true;
  this.listFields = Object.assign({}, DEFAULT_LIST_FIELDS);
  this.profileFields = [ ...DEFAULT_PROFILE_FIELDS ];
};
