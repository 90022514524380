import moment from 'moment';
import { DATE_TIME_FORMAT } from 'common/resource/module.constants';

function combineDateTime (date, time) {
  const t = moment(time);

  return moment(date).set({
    'hour': t.get('hour'),
    'minute': t.get('minute'),
    'second': t.get('second'),
  });
}

export function toDateTimeString (date, time = null) {
  if (time === null) {
    return moment(date).format(DATE_TIME_FORMAT);
  }

  return combineDateTime(date, time).format(DATE_TIME_FORMAT);
}

export function toDateTimeObject (date, time = null) {
  if (time === null) {
    return moment(date).toDate();
  }

  return combineDateTime(date, time).toDate();
}
