export default function ModuleConfig ($stateProvider) {
  'ngInject';

  $stateProvider.state('requestLog', {
    url: '/request-log?fromDate',
    template: '<request-log-page flex layout="column"></request-log-page>',
    params: {
      showDialog: false,
      location: null,
      fromDate: null,
    },
    data: {
      title: 'Request log',
    },
  }).state('incident', {
    url: '/incidents/:id',
    template: '<incident-page flex layout="column"></incident-page>',
    data: {
      title: 'Incident',
    },
  }).state('deployments', {
    url: '/deployments/:id',
    template: '<deployment-page flex layout="column"></deployment-page>',
    data: {
      title: 'Deployment',
    },
  }).state('prf', {
    abstract: true,
    url: '/prf/:id',
    template: '<prf-page flex layout="column"></prf-page>',
    data: {
      title: 'PRF',
    },
  }).state('prf.patientDetails', {
    url: '/patient-details',
    template: `<patient-details-tab flex layout="column" prf="$ctrl.prf" is-editing="$ctrl.isEditing" 
        relation-data="$ctrl.relationData" on-prf-update="$ctrl.onPrfUpdate(prf)"></patient-details-tab>`,
  }).state('prf.surveys', {
    url: '/surveys',
    template: `<survey-tab flex layout="column" prf="$ctrl.prf" is-editing="$ctrl.isEditing" 
        relation-data="$ctrl.relationData" on-prf-update="$ctrl.onPrfUpdate(prf)"></survey-tab>`,
  }).state('prf.handoverNotes', {
    url: '/handover-notes',
    template: `<handover-notes-tab flex layout="column" prf="$ctrl.prf" is-editing="$ctrl.isEditing" 
        on-prf-update="$ctrl.onPrfUpdate(prf)"></handover-notes-tab>`,
  }).state('prf.photos', {
    url: '/photos',
    template: `<photos-tab flex layout="column" prf="$ctrl.prf" is-editing="$ctrl.isEditing"
        on-prf-update="$ctrl.onPrfUpdate(prf)"></photos-tab>`,
  }).state('prf.attachments', {
    url: '/attachments',
    template: `<attachment-tab flex layout="column" prf="$ctrl.prf" is-editing="$ctrl.isEditing"
        on-prf-update="$ctrl.onPrfUpdate(prf)"></attachment-tab>`,
  }).state('prf.review', {
    url: '/review',
    template: `<review-qpi-tab flex layout="column" prf="$ctrl.prf" is-editing="$ctrl.userCanReviewPRF"
        relation-data="$ctrl.relationData" on-prf-update="$ctrl.onPrfUpdate(prf)"></review-qpi-tab>`,
  }).state('prf.followups', {
    url: '/followups',
    template: `<followup-tab flex layout="column" prf="$ctrl.prf" is-editing="$ctrl.userCanAddFollowup"
        relation-data="$ctrl.relationData" on-prf-update="$ctrl.onPrfUpdate(prf)"></followup-tab>`,
  });
}
