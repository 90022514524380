import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export const SECTIONS = [
  {
    name: 'Blood Gasses',
    attributes: [
        { label: 'pH', name: 'blood_gasses_ph', type: 'number' },
        { label: 'pCO2', name: 'blood_gasses_pco2', type: 'number' },
        { label: 'pO2', name: 'blood_gasses_po2', type: 'number' },
        { label: 'HCO3', name: 'blood_gasses_hco3', type: 'number' },
        { label: 'BE', name: 'blood_gasses_be', type: 'number', signed: true },
        { label: 'Lactate', name: 'blood_gasses_lactate', type: 'number' },
        { label: 'Glucose', name: 'blood_gasses_glucose', type: 'number' },
        { label: 'Na', name: 'blood_gasses_na', type: 'number' },
        { label: 'K', name: 'blood_gasses_k', type: 'number' },
    ],
  },
  {
    name: 'Haematology',
    attributes: [
        { label: 'Haemoglobin', name: 'haematology_hemoglobin', type: 'number' },
        { label: 'WCC', name: 'haematology_wcc', type: 'number' },
        { label: 'Neutrophils', name: 'haematology_neutrophils', type: 'number' },
        { label: 'Platelets', name: 'haematology_platelets', type: 'number' },
        { label: 'CRP', name: 'haematology_crp', type: 'number' },
        { label: 'PT', name: 'haematology_pt', type: 'number' },
        { label: 'INR', name: 'haematology_inr', type: 'number' },
        { label: 'APTT', name: 'haematology_aptt', type: 'number' },
    ],
  },
  {
    name: 'Biochemistry',
    attributes: [
        { label: 'Na', name: 'biochemistry_na', type: 'number' },
        { label: 'K', name: 'biochemistry_k', type: 'number' },
        { label: 'Calcium', name: 'biochemistry_calcium', type: 'number' },
        { label: 'Urea', name: 'biochemistry_urea', type: 'number' },
        { label: 'Creatinine', name: 'biochemistry_creatinine', type: 'number' },
        { label: 'Chloride', name: 'biochemistry_chloride', type: 'number' },
    ],
  },
  {
    name: 'Microbiology',
    attributes: [
        { label: 'Blood Cultures', name: 'microbiology_blood_cultures', type: 'text' },
        { label: 'LP', name: 'microbiology_lp', type: 'text' },
        { label: 'Stool', name: 'microbiology_stool', type: 'text' },
        { label: 'Urine', name: 'microbiology_urine', type: 'text' },
    ],
  },
  {
    name: 'Virology',
    attributes: [
        { label: 'NPA', name: 'virology_npa', type: 'text' },
        { label: 'Rapid flu test', name: 'virology_rapid_flu_test', type: 'text' },
        { label: 'Stool', name: 'virology_stool', type: 'text' },
        { label: 'Other', name: 'virology_urine', type: 'text' },
    ],
  },
];

export default class LaboratoryFindingsDialogController extends ResourceDialogController {
  init () {
    super.init();

    this.numericPattern = /^[0-9]{1,6}(\.[0-9]{1,2})?$/;
    this.signedNumericPattern = /^-?[0-9]{1,6}(\.[0-9]{1,2})?$/;
    this.sections = [ ...SECTIONS ];
  };

  submit (item) {
    if (!this.form) {
      throw new Error('Form not found');
    }

    if (this.form.$invalid) {
      return;
    }

    if (!item.id) {
      item.id = uuid.v4();
    }

    return this.Dialog.hide({ item });
  };

  delete (id) {
    this.Dialog.confirm().then(() => {
      this.Dialog.cancel(id);
    });
  }
}

