import RestResource from 'common/resource/services/restResource';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../common/resource/module.constants';

export function format (item) {
  const i = {
    id: item.id,
    name: item.name,
    archived: !!item.archived_at,
  };

  return i;
}

export function parse (item) {
  const i = {
    id: item.id,
    name: item.name,
    archived_at: item.archived ? moment().seconds(0).format(DATE_TIME_FORMAT) : null,
  };

  return i;
}

export default class PatientConsentResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'categories/patient-consents';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
};
