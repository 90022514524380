export default function parse (item) {
  return {
    id: item.id,
    notes: item.notes ? item.notes : null,
    status: item.status,
    body_part_id: item.body_part.id,
    illness_id: item.illness.id,
    illness_type_id: item.illness_type ? item.illness_type.id : null,
  };
}
