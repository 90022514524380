import RestResource from 'common/resource/services/restResource';

export default class QpiResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'qpi';
  }

  get (from, to) {
    const params = { date_from: from, date_to: to };
    return this._http.post(`${this.apiUrl}${this.resourcePath}`, params).then(res => res.data.data);
  }
}
