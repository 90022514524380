export default class PRFRejectionController {

  /**
   * @ngInject
   * @constructor
   */
  constructor (Dialog) {
    this._Dialog = Dialog;

    this.data = this.data || {};

    if (!this.data.comments) {
      this.data.comments = '';
    }
  }

  submit (data) {
    if (this.form.$invalid) return;
    return this._Dialog.hide(data);
  }

  cancel () {
    this._Dialog.cancel();
  }
}
