import parsePrescribedDrug from './prescribed-drug-parser.service';
import parseAdministeredDrug from './administered-drug-parser.service';
import parsePrescribedInfusion from './prescribed-infusion-parser.service';
import parseAdministeredInfusion from './administered-infusion-parser.service';
import parseAssessedIllness from './assessed-illness-parser.service';
import parseAssessedInjury from './assessed-injury-parser.service';
import parseAdministeredIntervention from './administered-intervention-parser.service';
import parseLaboratoryFindings from './laboratory-findings-parser.service';
import parseObservations from './observations-parser.service';
import { toDateTimeString } from '../../../common/utils/services/dateTime';

export default function parse (item) {
  let parsed = {
    id: item.id,
    time: toDateTimeString(item.date, item.time),
    prior_to_gnaas: item.prior_to_gnaas,
    name: item.name || null,
    comment: item.comment || null,
  };

  if (item.patient_report_form) {
    parsed.patient_report_form_id = item.patient_report_form.id;
  }

  if (item.observations) {
    parsed.observations = item.observations.map(parseObservations);
  }

  if (item.laboratory_findings) {
    parsed.laboratory_findings = item.laboratory_findings.map(parseLaboratoryFindings);
  }

  if (item.prescribed_drugs) {
    parsed.prescribed_drugs = item.prescribed_drugs.map(parsePrescribedDrug);
  }

  if (item.administered_drugs) {
    parsed.administered_drugs = item.administered_drugs.map(parseAdministeredDrug);
  }

  if (item.prescribed_infusions) {
    parsed.prescribed_infusions = item.prescribed_infusions.map(parsePrescribedInfusion);
  }

  if (item.administered_infusions) {
    parsed.administered_infusions = item.administered_infusions.map(parseAdministeredInfusion);
  }

  if (item.assessed_injuries) {
    parsed.assessed_injuries = item.assessed_injuries.map(parseAssessedInjury);
  }

  if (item.assessed_illnesses) {
    parsed.assessed_illnesses = item.assessed_illnesses.map(parseAssessedIllness);
  }

  if (item.administered_interventions) {
    parsed.administered_interventions = item.administered_interventions.map(parseAdministeredIntervention);
  }

  return parsed;
}
