import RestResource from 'common/resource/services/restResource';

export function format (item) {
  let formatted = {
    id: item.id,
    name: item.name,
  };

  if (item.requirements) {
    formatted.requirements = item.requirements.data.map(requirement => {
      let formattedRequirement = {
        id: requirement.id,
        name: requirement.name,
      };

      return formattedRequirement;
    });
  }

  return formatted;
}

export default class QualityIndicatorResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'quality-indicators';
  }

  format (item) {
    return format(item);
  }
}
