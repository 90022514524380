import ResourceDialog from 'common/resource/services/resourceDialog';
import FollowupIllnessDialogController from './followup-illness-dialog.controller';

export default class FollowupIllnessDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(FollowupIllnessDialogController);
    this.setTemplateUrl('core/prf/services/followup-illness-dialog/followup-illness-dialog.tpl.html');
  }
}
