import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';
import { copy } from 'angular';

export default class ReviewQpiDialogController extends ResourceDialogController {
  init () {
    this._Toast = this.$injector.get('Toast');
    this._resource = this.$injector.get('PRFResource');

    if (!this.item) {
      this.item = {
        id: uuid.v4(),
        reviewedIndicators: [],
      };
    }

    this.setUp();
  }

  submit (formData) {
    if (!this.form) {
      throw new Error('Form not found.');
    }

    if (this.form.$invalid) {
      return;
    }

    const requestData = {
      id: this.item.id,
      reviewed_indicators: [],
    };

    try {
      requestData.reviewed_indicators = this.data
        .map(this.mountRequestFailedRequirements)
        .filter(item => item.status !== 'na');
    } catch (err) {
      this._Toast.showSimple('A QPI with status "Fail" must have least one failed requirement.');
      return false;
    }

    this._resource
        .review(this.prfId, requestData)
        .then(review => {
          this.Dialog.hide(review);
        });
  };

  /**
   * This method is used to create a intermediate object between
   * quality indicators and the reviewed indicators
   */
  setUp () {
    this.data = this.relationData.qualityIndicators.map((item) => {
      return {
        reviewed_indicator_id: this.isIndicatorReviewed(item.id) ? this.getReviewedIndicator(item.id).id : null,
        quality_indicator_id: item.id,
        name: item.name,
        status: this.isIndicatorReviewed(item.id) ? this.getReviewedIndicatorStatus(item.id) : 'na',
        failed_requirements: (this.isIndicatorReviewed(item.id) && this.getReviewedIndicatorStatus(item.id) === 'fail')
            ? this.getFailedRequirements(item.id)
            : [],
      };
    });
  }

  mountRequestFailedRequirements (item) {
    const obj = copy(item);

    if (obj.status === 'fail' && obj.failed_requirements.length === 0) {
      throw new Error('Empty failed_requirements.');
    }

    obj.failed_requirements = obj.failed_requirements.map((reqItem) => {
      const reqObj = copy(reqItem);

      reqObj.quality_indicator_requirement_id = reqObj.id;

      delete reqObj.id;
      delete reqObj.name;

      return reqObj;
    });

    if (obj.status === 'pass') {
      obj.failed_requirements.length = 0;
    }

    obj.id = obj.reviewed_indicator_id ? obj.reviewed_indicator_id : uuid.v4();

    delete obj.reviewed_indicator_id;
    delete obj.name;

    return obj;
  }

  /** HELPERS **/

  findQualityIndicatorIndex (id) {
    return this.relationData.qualityIndicators.findIndex((item) => {
      return item.id === id;
    });
  }

  findIndicatorIndex (id) {
    return this.item.reviewedIndicators.findIndex((item) => {
      return item.indicator.id === id;
    });
  }

  getReviewedIndicator (id) {
    const i = this.findIndicatorIndex(id);

    if (i === -1) {
      return null;
    }

    return this.item.reviewedIndicators[i];
  }

  isIndicatorReviewed (id) {
    return this.getReviewedIndicator(id) !== null;
  }

  getReviewedIndicatorStatus (id) {
    const indicator = this.getReviewedIndicator(id);

    return indicator !== null ? indicator.status : null;
  }

  getFailedRequirements (id) {
    const indicator = this.getReviewedIndicator(id);

    return indicator !== null ? indicator.failedRequirements : [];
  }
}
