import { copy } from 'angular';
import uuid from 'node-uuid';
import deepEqual from 'deep-equal';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'common/resource/module.constants';

function createMarker (item) {
  return {
    ...item.body_part.marker,
    class: item.status,
  };
}

class FollowupTabController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $q, $rootScope, Dialog, FollowupIllnessDialog, FollowupInjuryDialog, Toast, FollowupResource, orderByFilter
  ) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.Dialog = Dialog;
    this.FollowupIllnessDialog = FollowupIllnessDialog;
    this.FollowupInjuryDialog = FollowupInjuryDialog;
    this.Toast = Toast;
    this.FollowupResource = FollowupResource;
    this.orderByFilter = orderByFilter;

    this.selectedFollowup = null;
    this.bodyMarkers = [];
    this.today = new Date();
  }

  $onInit () {
    this.stateChangeListener = this.$rootScope.$on('$stateChangeStart', (e) => {
      if (!this.checkSelectedFollowup()) {
        e.preventDefault();
      }
    });
  }

  $onDestroy () {
    this.stateChangeListener();
  }

  $onChanges (changes) {
    const { prf } = { ...changes };

    if (prf && prf.currentValue && prf.currentValue.followups && prf.currentValue.followups.length) {
      this.orderFollowups();

      const index = this.selectedFollowup ? this.prf.followups.indexOf(this.selectedFollowup) : 0;
      this.selectFollowup({ index });
    }
  }

  addFollowup () {
    if (!this.checkSelectedFollowup()) return false;
    const time = moment().startOf('minute').toDate();
    this.prf.followups = [...this.prf.followups, {
      time,
      injuries: [],
      illnesses: [],
      patient_report_form_id: this.prf.id,
    }];
    this.selectFollowup({ index: this.prf.followups.length - 1 });
    return true;
  }

  selectFollowup ({ index }) {
    if (!this.checkSelectedFollowup() || index == null) return false;
    this.selectedFollowup = this.prf.followups[index];
    this.selectedFollowupOriginal = copy(this.selectedFollowup);
    this.updateMarkers();
    return true;
  }

  saveSelectedFollowup () {
    if (this.form.$invalid) return false;

    const followup = copy(this.selectedFollowup);
    followup.time = moment(followup.time).format(DATE_TIME_FORMAT);

    if (this.selectedFollowup.id) {
      this.FollowupResource.update(followup.id, followup).then(() => {
        this.handleFollowupSaved('Followup updated.');
      }).catch(() => {
        this.Toast.showSimple('Error saving followup.');
      });
    } else {
      followup.id = uuid.v4();
      this.FollowupResource.create(followup).then(() => {
        this.selectedFollowup.id = followup.id;
        this.handleFollowupSaved('Followup created.');
      }).catch(() => {
        this.Toast.showSimple('Error creating followup.');
      });
    }
  }

  handleFollowupSaved (toastMsg) {
    this.orderFollowups();
    this.selectedFollowupOriginal = copy(this.selectedFollowup);
    this.selectFollowup({ index: this.prf.followups.indexOf(this.selectedFollowup) });
    this.Toast.showSimple(toastMsg);
    if (typeof this.onPrfUpdate === 'function') {
      this.onPrfUpdate({ prf: this.prf });
    }
  }

  deleteSelectedFollowup () {
    const index = this.prf.followups.indexOf(this.selectedFollowup);

    this.Dialog.confirm('Once deleted, followups can not be recovered.').then(() => {
      this.$q.when(this.selectedFollowup.id ? this.FollowupResource.destroy(this.selectedFollowup.id) : null)
        .then(data => {
          this.prf.followups = this.prf.followups.filter(item => {
            return data == null ? item !== this.selectedFollowup : item.id !== this.selectedFollowup.id;
          });
          this.handleFollowupDeleted(index);
        });
    });
  }

  handleFollowupDeleted (index) {
    this.orderFollowups();
    this.clearSelectedFollowup();

    if (this.prf.followups.length === 0) return;

    if (index > 0) {
      this.selectFollowup({ index: index - 1 });
    } else if (index === 0) {
      this.selectFollowup({ index: 0 });
    }

    this.Toast.showSimple('Followup deleted.');
  }

  discardSelectedFollowupChanges () {
    if (!this.isEditing || deepEqual(this.selectedFollowupOriginal, copy(this.selectedFollowup))) return;
    this.Dialog.confirm('This will reset all the changes you have made on the selected followup.').then(() => {
      Object.assign(this.selectedFollowup, this.selectedFollowupOriginal);
    });
  }

  clearSelectedFollowup () {
    this.selectedFollowup = null;
    this.selectedFollowupOriginal = null;
  }

  checkSelectedFollowup () {
    if (!this.isEditing || !this.selectedFollowupOriginal) return true;

    if (
      this.selectedFollowup &&
      (!this.selectedFollowup.id || !deepEqual(this.selectedFollowupOriginal, copy(this.selectedFollowup)))
    ) {
      this.Dialog.alert(
        'Please save, delete or discard the changes on selected followup.',
        'Followup has been changed.'
      );
      return false;
    }

    return true;
  }

  showInjuryDialog ({ $event, data }) {
    const isEditing = data && data.id != null;
    this.FollowupInjuryDialog
      .show({
        $event: $event,
        item: data,
        relationData: { bodyParts: this.relationData.bodyParts },
      })
      .then(({ item, addAnother }) => {
        this.selectedFollowup.injuries =
            this.handleCreateOrUpdate(isEditing, this.selectedFollowup.injuries, item);
        if (addAnother) {
          const newItem = copy(item);
          delete newItem.id;
          delete newItem.notes;
          delete newItem.injury_type;
          this.showInjuryDialog({ $event, data: newItem });
        }
      }).catch(id => {
        this.selectedFollowup.injuries = this.handleDelete(this.selectedFollowup.injuries, id);
      }).finally(() => {
        this.updateMarkers();
      });
  }

  showIllnessDialog ({ $event, data }) {
    const isEditing = data && data.id != null;
    this.FollowupIllnessDialog
      .show({
        $event: $event,
        item: data,
        relationData: { bodyParts: this.relationData.bodyParts },
      })
      .then(({ item, addAnother }) => {
        this.selectedFollowup.illnesses =
            this.handleCreateOrUpdate(isEditing, this.selectedFollowup.illnesses, item);
        if (addAnother) {
          const newItem = copy(item);
          delete newItem.id;
          delete newItem.notes;
          delete newItem.illness_type;
          this.showIllnessDialog({ $event, data: newItem });
        }
      }).catch(id => {
        this.selectedFollowup.illnesses = this.handleDelete(this.selectedFollowup.illnesses, id);
      }).finally(() => {
        this.updateMarkers();
      });
  }

  //
  // BODY MARKERS
  //

  updateMarkers () {
    this.bodyMarkers = [
      ...this.selectedFollowup.injuries.map(injury => createMarker(injury)),
      ...this.selectedFollowup.illnesses.map(illness => createMarker(illness)),
    ];
  }

  handleCreateOrUpdate (isEditing, array, item) {
    if (isEditing) {
      return array.map(i => i.id === item.id ? { ...i, ...item } : i);
    }
    return [...array, item];
  }

  handleDelete (array, deletedItemId) {
    return array.filter(({ id }) => id !== deletedItemId);
  }

  orderFollowups () {
    this.prf.followups = this.orderByFilter(this.prf.followups, 'interval.order');
  }
}

export default {
  controller: FollowupTabController,
  templateUrl: 'pages/requestLog/components/followup-tab/followup-tab.tpl.html',
  bindings: {
    isEditing: '<',
    prf: '<',
    relationData: '<',
    onPrfUpdate: '&',
  },
};
