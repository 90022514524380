/* global setTimeout */
import L from 'leaflet';
import BingMapsLayer from './bing-maps-layer';

const getDefaultOpts = function () {
  return {
    map: {
      center: [54.680116, -2.262684],
      zoom: 9,
      minZoom: 9,
      scrollWheelZoom: true,
    },
    tileLayers: {
      '500k': {
        minZoom: 9,
        maxZoom: 10,
        opacity: 1.0,
        tms: false,
        bounds: L.latLngBounds(
          L.latLng(52.911301, -9.247032),
          L.latLng(56.461343, 1.277031)
        ),
      },
      '250k': {
        minZoom: 11,
        maxZoom: 12,
        opacity: 1.0,
        tms: false,
      },
    },
  };
};

export default class Map {
  constructor (elementId, credentials, opts) {
    if (!credentials || !credentials.bingMapsKey) {
      throw new Error('Bing maps key required');
    }
    this._credentials = credentials;

    this._map = this._initMap(elementId, opts);
    this._markers = L.featureGroup([]).addTo(this._map);
    this._routePlanning = false;
    this._routePoints = [];
    this._routePlotLine = null;
    this._routeStartMarker = null;
  }

  destroy () {
    this._map.remove();
  }

  /**
   * Getters
   */
  getMap () {
    return this._map;
  }

  /**
   * Events
   */
  addEventListener (event, handler) {
    this._map.on(event, handler);
  }

  removeEventListener (event, handler) {
    this._map.off(event, handler);
  }

  addOneTimeEventListener (event, handler) {
    this._map.once(event, handler);
  }

  /**
   * Markers
   */

  addMarker (latLng, opts, popup) {
    let marker = L.marker(latLng, opts);
    if (popup) {
      marker.bindPopup(popup);
    }
    this._markers.addLayer(marker);
    return marker;
  }

  removeMarker (marker) {
    this._markers.removeLayer(marker);
  }

  panTo (latLng) {
    this._map.panTo(latLng);
  }

  openPopup (popup) {
    this._map.openPopup(popup);
  }

  closePopup (popup) {
    this._map.closePopup(popup);
  }

  /**
   * Route planning
   */

  enableRoutePlanning (cb) {
    this._routePlanning = true;
    // Store a reference to route planning map and map marker
    // handlers bound with a callback function
    this._boundRoutePlanningMapHandler = this._routePlanningMapHandler.bind(this, cb);
    this._boundRoutePlanningMapMarkerHandler = this._routePlanningMapMarkerHandler.bind(this, cb);
    this._map.on('click', this._boundRoutePlanningMapHandler);
    this._markers.on('click', this._boundRoutePlanningMapMarkerHandler);
  }

  disableRoutePlanning () {
    this._routePlanning = false;
    this._map.off('click', this._boundRoutePlanningMapHandler);
    this._markers.off('click', this._boundRoutePlanningMapMarkerHandler);
  }

  addRoutePoint (point) {
    this._routePoints.push(point);

    if (this._routePoints.length === 1) {
      this._routePlotLine = L.polyline(this._routePoints, { color: 'red' }).addTo(this._map);
      this._routeStartMarker = L.marker(this._routePoints[0]);
      this._map.addLayer(this._routeStartMarker);
    } else if (this._routePoints.length > 1) {
      this._routePlotLine.addLatLng(this._routePoints[this._routePoints.length - 1]);
    }
  }

  getRouteDistance () {
    if (this._routePoints.length < 2) {
      return 0;
    }

    return this._routePoints.reduce((previous, current, currentIndex) => {
      if (typeof previous === 'object') {
        return previous.distanceTo(current);
      }
      return previous + this._routePoints[currentIndex - 1].distanceTo(current);
    });
  }

  clearRoute () {
    if (!this._routePlotLine) {
      return;
    }
    this._routePoints.length = 0;
    this._map.removeLayer(this._routePlotLine);
    this._map.removeLayer(this._routeStartMarker);
    this._routePlotLine = null;
    this._routeStartMarker = null;
  }

  /**
   * Private methods
   */
  _routePlanningMapHandler (cb, e) {
    if (this._routePlanning) {
      this.addRoutePoint(e.latlng);
      if (typeof cb === 'function') {
        cb();
      }
    }
  }

  _routePlanningMapMarkerHandler (cb, e) {
    if (this._routePlanning) {
      e.layer.closePopup();
      this.addRoutePoint(e.latlng);
      if (typeof cb === 'function') {
        cb();
      }
    }
  }

  _initMap (elementId, opts = {}) {
    const defaultOpts = getDefaultOpts();
    const { bingMapsKey } = this._credentials;

    const map = L.map(elementId, Object.assign({}, defaultOpts.map, opts.map));
    map.attributionControl.setPrefix('');

    const layers = [
      BingMapsLayer({ bingMapsKey: bingMapsKey, imagerySet: 'OrdnanceSurvey', minZoom: 13, maxZoom: 17 }),
      BingMapsLayer({ bingMapsKey: bingMapsKey, minZoom: 18, maxZoom: 19 }),
    ];
    layers.forEach(layer => map.addLayer(layer));

    const _500kLayerOpts = Object.assign({}, defaultOpts.tileLayers['500k']);
    const _250kLayerOpts = Object.assign({}, defaultOpts.tileLayers['250k']);
    if (opts.layers) {
      Object.assign(_500kLayerOpts, opts.layers['500k']);
      Object.assign(_250kLayerOpts, opts.layers['250k']);
    }
    L.tileLayer('tiles/{z}/{x}/{y}.png', _500kLayerOpts).addTo(map);  // 500k tile layer
    L.tileLayer('tiles/{z}/{x}/{y}.png', _250kLayerOpts).addTo(map);  // 250k tile layer

    return map;
  }
}
