import { copy } from 'angular';
import { DATE_FORMAT } from 'common/resource/module.constants';

export class IncidentPageController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $state, $stateParams, IncidentPageService, Toast, Utils, FlaggedIncidentDialog,
    DeploymentPageService, DeploymentDialog, IncidentDialog, Session, ShiftService,
    DeploymentStatusUpdateDialog
  ) {
    this._state = $state;
    this._stateParams = $stateParams;
    this._Toast = Toast;
    this._Utils = Utils;
    this._IncidentPageService = IncidentPageService;
    this._FlaggedIncidentDialog = FlaggedIncidentDialog;
    this._DeploymentPageService = DeploymentPageService;
    this._DeploymentDialog = DeploymentDialog;
    this._IncidentDialog = IncidentDialog;
    this._Session = Session;
    this._ShiftService = ShiftService;
    this._DeploymentStatusUpdateDialog = DeploymentStatusUpdateDialog;
  }

  $onInit () {
    this._incidentRelationData = {};
    this._deploymentRelationData = { shifts: [] };

    if (this.hasPermissionToEditIncident()) {
      this._loadRelationData();
    }

    this._IncidentPageService.getIncident(this._stateParams.id).then(incident => {
      this.incident = incident;
      this.incident.outcome = this._IncidentPageService.getIncidentOutcome(this.incident);
      this.incident.deployments.map(deployment => {
        deployment.outcome = this._DeploymentPageService.getDeploymentOutcomes(deployment);
        return deployment;
      });
      this.unresolvedFlagCount = this.incident.flagged_incidents.filter(i => !i.resolved).length;
      this._generateTimeline();
      this._generateBreadCrumbs();
      if (this.hasPermissionToEditIncident()) {
        this._loadShiftCrewData(this.incident.date.format(DATE_FORMAT));
      }
    });
  }

  hasPermissionToEditIncident () {
    const user = this._Session.user();
    if (!user || !user.group) return false;
    return ['system-administrators', 'medical-staff', 'ops-admins', 'pilots'].indexOf(user.group.slug) > -1;
  }

  showTimelineDialog ($event, item) {
    if (!item.type || item.type !== 'deployment') {
      return this.showIncidentDialog($event);
    }

    return this.showDeploymentStatusUpdateDialog($event, item);
  }

  showDeploymentStatusUpdateDialog ($event, item) {
    const _deployment = this._findDeploymentByTimelineEventLabel(item.label);
    const _deploymentStatus = this._findDeploymentStatusByTimelineEventLabel(item.label);

    let deployment = copy(_deployment);
    deployment.name = deployment.vehicle_callsign.name;

    let deploymentStatus = copy(_deploymentStatus);

    const isEditing = !!item;

    if (isEditing) {
      deploymentStatus.time = deploymentStatus.time.toDate();
    }

    const dialogParams = {
      $event: $event,
      item: deploymentStatus,
      relationData: this._deploymentRelationData,
      locals: {
        deployment: copy(deployment),
      },
    };

    this._DeploymentStatusUpdateDialog.show(dialogParams).then(status => {
      if (isEditing) {
        _deployment.deployment_statuses = _deployment.deployment_statuses.map(
          item => item.id === status.id ? Object.assign({}, item, status) : item
        );
      } else {
        _deployment.deployment_statuses = [..._deployment.deployment_statuses, status];
      }

      _deployment.outcome = this._DeploymentPageService.getDeploymentOutcomes(_deployment);

      this._generateTimeline();
      this._Toast.showSimple('Deployment status updated.');
    }).catch(statusId => {
      if (!statusId) {
        return;
      }

      _deployment.deployment_statuses = _deployment.deployment_statuses.filter(({ id }) => id !== statusId);
      _deployment.outcome = this._DeploymentPageService.getDeploymentOutcomes(_deployment);

      this._generateTimeline();
      this._Toast.showSimple('Deployment status deleted.');
    });
  }

  showIncidentDialog ($event) {
    const dialogParams = {
      $event: $event,
      item: copy(this.incident),
      editing: true,
      relationData: this._incidentRelationData,
      multiple: true,
    };

    this._IncidentDialog.show(dialogParams).then(incident => {
      this.incident = Object.assign({}, this.incident, incident);
      this._loadShiftCrewData(this.incident.date.format(DATE_FORMAT));
      this._generateTimeline();
      this._Toast.showSimple('Incident updated.');
    }).catch(incidentId => {
      if (typeof incidentId !== 'string' || !incidentId) {
        return;
      }
      this._state.go('requestLog');
      this._Toast.showSimple('Incident deleted.');
    });
  }

  showDeploymentDialog ($event) {
    const dialogParams = {
      $event: $event,
      editing: false,
      relationData: this._deploymentRelationData,
      locals: {
        incident: this.incident,
      },
    };

    this._DeploymentDialog.show(dialogParams).then(deployment => {
      this._Toast.showSimple('Deployment created.');
      this._state.go('deployments', { id: deployment.id });
    });
  }

  showFlagIncidentDialog ($event) {
    this._FlaggedIncidentDialog
      .show({ $event, incident: this.incident })
      .then(() => {
        this._Toast.showSimple('Incident flagged.');
        this.unresolvedFlagCount++;
      })
      .catch(e => {
        if (e != null && e.status) {
          this._Toast.showSimple('Error when flagging incident.');
        }
      });
  }

  _generateTimeline () {
    this.timelineEvents = this._IncidentPageService.generateTimeline(
      this.incident.times,
      this.incident.deployments
    );
  }

  _loadShiftCrewData (date) {
    const include = 'properties.vehicleCallsign,users.profile.image,users.profile.profession';
    this._ShiftService.getShiftsForDate(date, include).then(shifts => {
      this._deploymentRelationData.shifts = [...this._deploymentRelationData.shifts, ...shifts];
      this._ShiftService.getOvernightShiftsForDate(date).then(data => {
        const overnightShifts = data.map(shift => ({ ...shift, name: `${shift.name} (previous day)` }));
        this._deploymentRelationData.shifts = [...this._deploymentRelationData.shifts, ...overnightShifts];
      });
    });
  }

  _loadRelationData () {
    this._IncidentPageService.getRelationData().then(relationData => {
      Object.assign(this._incidentRelationData, relationData);
    });
    this._DeploymentPageService.getRelationData().then(relationData => {
      Object.assign(this._deploymentRelationData, relationData);
    });

    this._DeploymentPageService.getDestinationData().then(relationData => {
      Object.assign(this._deploymentRelationData, { destinations: relationData });
    });
  }

  _generateBreadCrumbs () {
    this.routes = [
      {
        title: 'Request Log',
        state: 'requestLog()',
      },
      {
        title: 'Incident',
      },
    ];
  }

  _findDeploymentByTimelineEventLabel (label) {
    if (!this.incident.deployments) {
      return;
    }

    let _deployment = this.incident.deployments.find((deployment) => {
      let _status = deployment.deployment_statuses.find((status) => {
        return deployment.vehicle_callsign.name + ' - ' + status.deployment_status.name === label;
      });

      return _status !== undefined;
    });

    return _deployment;
  }

  _findDeploymentStatusByTimelineEventLabel (label) {
    if (!this.incident.deployments) {
      return;
    }

    let returnStatus = null;

    this.incident.deployments.find((deployment) => {
      let _status = deployment.deployment_statuses.find((status) => {
        return deployment.vehicle_callsign.name + ' - ' + status.deployment_status.name === label;
      });

      returnStatus = _status;

      return _status !== undefined;
    });

    return returnStatus;
  }
}

export default {
  controller: IncidentPageController,
  templateUrl: 'pages/requestLog/components/incident-page/incident-page.tpl.html',
};
