export default {
  /**
   * CALENDAR
   */
  'calendar-event-types': {
    controller: 'CreateCalendarEventTypeController',
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Calendar Event Type',
      multiple: 'Calendar Event Types',
    },
    resource: 'CalendarEventTypeResource',
  },

  /**
   * CONSUMABLES
   */
  'consumable-types': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Consumable Type',
      multiple: 'Consumable Types',
    },
    resource: 'ConsumableTypeResource',
  },

  /**
   * DEPLOYMENTS
   */
  /*
  'abort-reasons': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Abort Reason',
      multiple: 'Abort Reasons',
    },
    resource: 'AbortReasonResource',
  },
   */

  'deployment-statuses': {
    controller: 'CreateDeploymentStatusController',
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Deployment Status',
      multiple: 'Deployment Statuses',
    },
    resource: 'DeploymentStatusResource',
  },

  /**
   * LOCATIONS
   */
  'locations': {
    fields: {
      'name': 'Name',
    },
    form: 'location.tpl.html',
    labels: {
      single: 'Location',
      multiple: 'Locations',
    },
    resource: 'LocationResource',
    query: {
      include: 'locationType',
    },
    relationData: {
      'locationTypes': {
        resource: 'LocationTypeResource',
        method: 'index',
      },
    },
  },

  'location-types': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Location Type',
      multiple: 'Location Types',
    },
    resource: 'LocationTypeResource',
  },

  'infusion-solutions': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Infusion Solution',
      multiple: 'Infusion Solutions',
    },
    resource: 'InfusionSolutionResource',
  },

  'medication-routes': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Medication Route',
      multiple: 'Medication Routes',
    },
    resource: 'MedicationRouteResource',
  },

  /**
   * HOSPITALS
   */
  'hospital-specialties': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Hospital Specialty',
      multiple: 'Hospital Specialties',
    },
    resource: 'HospitalSpecialtyResource',
  },

  /**
   * INCIDENTS
   */
  'ambulance-services': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Ambulance Service',
      multiple: 'Ambulance Services',
    },
    resource: 'AmbulanceServiceResource',
  },

  'incident-types': {
    controller: 'CreateIncidentTypeController',
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Incident Type',
      multiple: 'Incident Types',
    },
    resource: 'IncidentTypeResource',
  },

  'deployment-reasons': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Deployment Reason',
      multiple: 'Deployment Reasons',
    },
    resource: 'DeploymentReasonResource',
  },

  'refusal-reasons': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Refusal Reason',
      multiple: 'Refusal Reasons',
    },
    resource: 'RefusalReasonResource',
  },

  'service-areas': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Service Area',
      multiple: 'Service Areas',
    },
    resource: 'ServiceAreaResource',
  },

  /**
   * PRFs
   */
  'body-parts': {
    controller: 'CreateBodyPartController',
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Body Region',
      multiple: 'Body Regions',
    },
    resource: 'BodyPartResource',
    relationData: {
      'injuries': {
        resource: 'InjuryResource',
        method: 'index',
      },
      'illnesses': {
        resource: 'IllnessResource',
        method: 'index',
      },
      'interventions': {
        resource: 'InterventionResource',
        method: 'index',
      },
    },
    multiSelections: ['injuries', 'illnesses', 'interventions'],
  },

  'injuries': {
    controller: 'CreateIllnessAndInjuryController',
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Injury',
      multiple: 'Injuries',
    },
    resource: 'InjuryResource',
  },

  'interventions': {
    controller: 'CreateInterventionController',
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Intervention',
      multiple: 'Interventions',
    },
    relationData: {
      'interventionAttributeTypes': {
        resource: 'InterventionResource',
        method: 'getAttributeTypes',
      },
    },
    resource: 'InterventionResource',
  },

  'illnesses': {
    controller: 'CreateIllnessAndInjuryController',
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Illness',
      multiple: 'Illnesses',
    },
    resource: 'IllnessResource',
  },

  'patient-types': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Patient Type',
      multiple: 'Patient Types',
    },
    resource: 'PatientTypeResource',
  },

  'patient-consents': {
    controller: 'CreateArchivableCategoryController',
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Patient Consent',
      multiple: 'Patient Consents',
    },
    resource: 'PatientConsentResource',
  },

  'followup-intervals': {
    controller: 'CreateFollowupIntervalController',
    fields: {
      'order': 'Order',
      'name': 'Name',
    },
    labels: {
      single: 'Followup Interval',
      multiple: 'Followup Intervals',
    },
    resource: 'FollowupIntervalResource',
  },

  'followup-outcomes': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Followup Outcome',
      multiple: 'Followup Outcomes',
    },
    resource: 'FollowupOutcomeResource',
  },

  /**
   * USERS
   */
  'professions': {
    fields: {
      'title': 'Title',
    },
    form: 'profession.tpl.html',
    labels: {
      single: 'Profession',
      multiple: 'Professions',
    },
    resource: 'ProfessionResource',
  },

  /**
   * VEHICLES
   */
  'offline-reasons': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Offline Reason',
      multiple: 'Offline Reasons',
    },
    resource: 'OfflineReasonResource',
  },

  /**
   * STOCK
   */
  'suppliers': {
    fields: {
      'name': 'Name',
    },
    form: 'supplier.tpl.html',
    labels: {
      single: 'Supplier',
      multiple: 'Suppliers',
    },
    resource: 'SupplierResource',
  },

  'stock-locations': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Stock Location',
      multiple: 'Stock Locations',
    },
    resource: 'StockLocationResource',
  },
};
