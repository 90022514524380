import angular from 'angular';
import uiRouter from 'angular-ui-router';
import FeatureFlagResourceController from './controllers/featureFlagResourceController';
import FeatureFlagResource from './services/featureFlagResource';
import FeatureFlag from './services/featureFlag';

const moduleConfig = function ($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.featureFlags', {
    url: '/feature-flags',
    controller: FeatureFlagResourceController,
    controllerAs: '$ctrl',
    templateUrl: 'common/resource/templates/resource.tpl.html',
    data: {
      title: 'Feature Flags',
    },
  });
};

const module = angular
  .module('gnaas.pages.admin.featureFlags', [
    uiRouter,
  ])
  .config(moduleConfig)
  .service('FeatureFlagResource', FeatureFlagResource)
  .service('FeatureFlag', FeatureFlag);

export default module.name;
