import RestResource from 'common/resource/services/restResource';

export function format (item) {
  const i = {
    id: item.id,
    name: item.name,
    followup_only: !!item.followup_only,
  };
  if (item.injuryTypes) {
    i.types = item.injuryTypes.data;
  }
  return i;
}

export function parse (item) {
  const i = {
    id: item.id,
    name: item.name,
    followup_only: item.followup_only ? 1 : 0,
  };
  if (item.types) i.types = item.types;
  return i;
}

export default class InjuryResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'categories/injuries';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
};
