import ResourceDialog from 'common/resource/services/resourceDialog';
import TimestampDialogController from './timestamp-dialog.controller';

export default class TimestampDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(TimestampDialogController);
    this.setTemplateUrl('common/services/timestamp-dialog/timestamp-dialog.tpl.html');
  }
}
