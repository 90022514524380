import uuid from 'node-uuid';
import moment from 'moment';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

const PROMPT_SELECT_TPL = `<md-dialog class="o-dialog o-dialog--small">
    <form novalidate name="$ctrl.form" ng-submit="$ctrl.submit()">
        <md-dialog-content>
            <div class="md-dialog-content">
                Multiple shift crews available for {{ $ctrl.callsignName }}.
                <br>
                Please select one, or press 'Cancel' to keep current crew.
            </div>
            <form-select fe-label="Shifts" fe-name="selected" fe-required="true" fe-model="$ctrl.selected"
                fe-options="$ctrl.options" fe-errors="$ctrl.form.selected.$error"></form-select>
        </md-dialog-content>
        <md-dialog-actions>
            <md-button class="md-raised" ng-click="$ctrl.close()">Cancel</md-button>
            <md-button class="md-raised md-primary" type="submit">Submit</md-button>
        </md-dialog-actions>
    </form>
</md-dialog>`;

const TYPE_MEDIC = 'medic';
const TYPE_OBSERVER = 'observer';
const TYPE_PILOT = 'pilot';
const TYPE_WEIGHTS = {
  [TYPE_PILOT]: 1,
  [TYPE_MEDIC]: 2,
  [TYPE_OBSERVER]: 3,
};
export const TYPES = [
  { name: 'Medic', value: TYPE_MEDIC },
  { name: 'Observer', value: TYPE_OBSERVER },
  { name: 'Pilot', value: TYPE_PILOT },
];

function formatCrewMembers (items) {
  return items.map(item => {
    item.name = `${item.first_name} ${item.last_name}`;
    item.disabled = false;
    return item;
  });
}

export default class DeploymentDialogController extends ResourceDialogController {
  init () {
    super.init();
    this._scope = this.$injector.get('$rootScope').$new();
    this._state = this.$injector.get('$state');
    this._timeout = this.$injector.get('$timeout');
    this._resource = this.$injector.get('DeploymentResource');
    this._DEPLOYMENT_ID = uuid.v4();
    this.types = TYPES;

    if (!this.item) {
      this.item = {
        incident: this.incident,
        date: moment(this.incident.date).toDate(),
        crew_members: [],
        deployment_statuses: [],
      };
    } else {
      this.item.date = moment(this.item.date).toDate();
      this.item.crew_members = this.item.crew_members.sort((a, b) => {
        if (TYPE_WEIGHTS[a.type] > TYPE_WEIGHTS[b.type]) return 1;
        if (TYPE_WEIGHTS[a.type] < TYPE_WEIGHTS[b.type]) return -1;
        if (TYPE_WEIGHTS[a.type] === TYPE_WEIGHTS[b.type] && (a.type !== TYPE_OBSERVER || !a.id && !b.id)) return 0;
        return a.id && !b.id ? 1 : -1;
      });
    }

    this.item.incident.date = moment(this.item.incident.date).toDate();
    this.vehicleCallsigns = this.getVehicleCallsigns();
    this.shiftCrew = this.getShiftCrew();
    this.setCrewMembers(this.shiftCrew.length ? this.shiftCrew : this.relationData.users);
    this.setupWatchers();
  }

  setCrewMembers (users) {
    const crewMembers = [...users];
    this.item.crew_members.forEach(crewMember => {
      if (!crewMembers.find(i => i.id === crewMember.id)) {
        crewMembers.push({ ...crewMember });
      }
    });
    this.crewMembers = formatCrewMembers(crewMembers);
    this.observers = [{ id: null, name: 'OTHER' }, ...crewMembers];
  }

  addCrewMember () {
    this.item.crew_members = [...this.item.crew_members, {}];
  }

  removeCrewMember (i) {
    this.item.crew_members = [...this.item.crew_members.slice(0, i), ...this.item.crew_members.slice(i + 1)];
  }

  delete (id) {
    this.Dialog.promptDelete().then(() => {
      this._resource.destroy(id)
        .then(() => {
          this.Dialog.cancel(id);
        })
        .catch(err => {
          let msg = 'Error deleting deployment.';
          if (err.status && err.status === 409) {
            msg = err.data.message;
          }
          this.Toast.showSimple(msg);
        });
    });
  }

  create (formData) {
    formData.id = this._DEPLOYMENT_ID;
    formData.vehicle = formData.vehicle_callsign.vehicle;

    const copy = Object.assign({}, formData);
    copy.date = moment(formData.date);

    this._resource.create(copy).then(() => {
      this.Dialog.hide(formData);
    }).catch(err => {
      let msg = 'Error creating deployment.';
      if (err.status && err.status === 422 && err.data.errors && err.data.errors.vehicle_callsign_id) {
        msg = err.data.errors.vehicle_callsign_id[0];
      }
      this.Toast.showSimple(msg);
    });
  }

  update (formData) {
    const copy = Object.assign({}, formData);
    copy.date = moment(formData.date);

    this._resource.update(formData.id, copy, {
      include: [
        'incident', 'vehicleCallsign', 'vehicle', 'users',
        'deploymentStatuses.deploymentStatus', 'incident.deployments',
        'incident.deployments.vehicleCallsign', 'patientReportForms',
      ],
    }).then(deployment => {
      this.Dialog.hide(deployment);
    }).catch(err => {
      let msg = 'Error updating deployment.';
      if (err.status && err.status === 422 && err.data.errors && err.data.errors.vehicle_callsign_id) {
        msg = err.data.errors.vehicle_callsign_id[0];
      }
      this.Toast.showSimple(msg);
    });
  }

  getVehicleCallsigns () {
    const today = moment().startOf('day');
    const incidentDate = moment(this.incident.date).startOf('day');
    return this.relationData.vehicleCallsigns.map(vehicleCallsign => {
      vehicleCallsign.disabled = false;
      const statusUpdate = vehicleCallsign.latest_status_update;
      // If the incident date is today & vehicle is either offline or it's last status update is
      // today and is not a final status - disable it from selection.
      if (
        incidentDate.isSame(today) &&
        (
          !vehicleCallsign.online ||
          (statusUpdate && incidentDate.isSame(statusUpdate.time, 'day') &&
            !statusUpdate.deployment_status.is_final_status)
        )
      ) {
        vehicleCallsign.disabled = true;
      }
      return vehicleCallsign;
    });
  }

  getShiftCrew () {
    return this.relationData.shifts.reduce((acc, cur) => {
      if (!cur.users.length) return acc;
      const uniqueUsers = cur.users.filter(user => !acc.find(({ id }) => id === user.id));
      return [...acc, ...uniqueUsers];
    }, []);
  }

  showCrewSelectionDialog (callsign, shifts) {
    const _this = this;
    // It's possible to have more than one shift with the same ID, hence they need to be made unique.
    shifts = shifts.map((shift, i) => ({ ...shift, id: shift.id + '-' + i }));
    return this.Dialog.show({
      controller: function () {
        this.callsignName = callsign.name;
        this.options = shifts;
        this.selected = null;
        this.close = () => (_this.Dialog.cancel());
        this.submit = () => {
          if (this.form.$invalid) return;
          _this.Dialog.hide(this.selected);
        };
      },
      multiple: true,
      targetEvent: null,
      template: PROMPT_SELECT_TPL,
    });
  }

  setupWatchers () {
    this._scope.$watch(
      () => this.item.vehicle_callsign,
      (newVal, oldVal) => {
        if (newVal && oldVal && newVal.id === oldVal.id) return;
        if (!this.shiftCrew.length) return this.setCrewMembers(this.relationData.users);
        if (!newVal) return this.setCrewMembers(this.shiftCrew);

        const shifts = this.relationData.shifts.filter(shift => {
          return shift.vehicle_callsign && shift.vehicle_callsign.id === newVal.id;
        });

        if (!shifts.length) return;

        if (shifts.length === 1) {
          if (!this.item.crew_members.length) {
            this.item.crew_members = formatCrewMembers(shifts[0].users);
          } else {
            this.Dialog.confirm(`Would you like to set the ${shifts[0].name} crew?`).then(() => {
              this.item.crew_members = formatCrewMembers(shifts[0].users);
            });
          }
        } else {
          this.showCrewSelectionDialog(newVal, shifts)
            .then(crew => (this.item.crew_members = formatCrewMembers(crew.users)));
        }
      }
    );

    this._scope.$watch(
      () => this.item.crew_members,
      (newVal, oldVal) => {
        this._timeout(() => {
          const ids = newVal.map(i => i.id);
          this.crewMembers.forEach(i => {
            if (i.id == null) return;
            i.disabled = ids.indexOf(i.id) > -1;
          });
        });
      },
      true
    );
  }
}
