import moment from 'moment';

export default function parse (item) {
  let parsed = {
    id: item.id,
    first_name: item.first_name,
    last_name: item.last_name,
    gender: item.gender,
    nhs_number: item.nhs_number,
    address: item.address,
    details: item.details,
    incident_description: item.incident_description,
    handover_notes: item.handover_notes,
    allergies: item.allergies,
  };

  if (item.date_of_birth) {
    parsed.date_of_birth = moment(item.date_of_birth).format('YYYY-MM-DD');
  }

  if (item.weight) {
    parsed.weight = Math.round(item.weight / 0.001);
  }

  if (item.patient_type) {
    parsed.patient_type_id = item.patient_type.id;
  }

  if (item.patient_consent) {
    parsed.patient_consent_id = item.patient_consent.id;
  }

  if (item.deployment) {
    parsed.deployment_id = item.deployment.id;
  }

  return parsed;
}
