import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class CreateQualityIndicatorController extends ResourceDialogController {
  init () {
    super.init();
    this._resource = this.$injector.get('QualityIndicatorResource');
    this._Utils = this.$injector.get('Utils');
    this._ID = uuid.v4();

    if (!this.item) {
      this.item = { requirements: [] };
    }
  }

  addRequirement () {
    this.item.requirements.push({
      id: uuid.v4(),
    });
  }

  removeRequirement (item) {
    this._Utils.removeFromArray(this.item.requirements, item);
  }

  create (data) {
    data.id = this._ID;
    this._resource
      .create(data)
      .then(() => {
        this.Dialog.hide(data);
      })
      .catch(console.log.bind(console));
  };

  update (data) {
    this._resource
      .update(data.id, data)
      .then(item => {
        this.Dialog.hide(item);
      })
      .catch(console.log.bind(console));
  };

  delete (id) {
    this.Dialog
      .confirm()
      .then(() => {
        this._resource
          .destroy(id)
          .then(() => {
            this.Dialog.cancel(id);
          });
      });
  };
}
