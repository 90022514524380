import angular from 'angular';
import uiRouter from 'angular-ui-router';
import AuditToolPage from './components/audit-tool-page/audit-tool-page.component';
import KPIPage from './components/kpi-page/kpi-page.component';
import QPIPage from './components/qpi-page/qpi-page.component';
import AuditToolResource from './resources/audit-tool.resource';

const moduleConfig = function ($stateProvider) {
  'ngInject';
  $stateProvider.state('auditTool', {
    url: '/audit-tool',
    template: '<audit-tool-page flex layout="column"></audit-tool-page>',
    data: {
      title: 'Audit Tool',
    },
  }).state('kpi', {
    url: '/kpi',
    template: '<kpi-page flex layout="column"></kpi-page>',
    data: {
      title: 'KPI',
    },
  }).state('qpi', {
    url: '/qpi',
    template: '<qpi-page flex layout="column"></qpi-page>',
    data: {
      title: 'Quality Performance Indicators',
    },
  });
};

const module = angular
  .module('gnaas.pages.auditTool', [
    uiRouter,
  ])
  .config(moduleConfig)
  .component('auditToolPage', AuditToolPage)
  .component('kpiPage', KPIPage)
  .component('qpiPage', QPIPage)
  .service('AuditToolResource', AuditToolResource);

export default module.name;
