import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';
import { FEATURE_FLAG_EVENTS } from 'pages/admin/feature-flags/module.constants';

export default class CreateFeatureFlagController extends ResourceDialogController {
  init () {
    super.init();
    this._resource = this.$injector.get('FeatureFlagResource');
    this._Utils = this.$injector.get('Utils');
    this._rootScope = this.$injector.get('$rootScope');
    this._ID = uuid.v4();

    if (!this.item) {
      this.item = { };
    }

    this.hideDeleteButton = true;
  }

  update (data) {
    this._resource
      .update(data.id, data)
      .then(item => {
        this._rootScope.$emit(FEATURE_FLAG_EVENTS.featureFlagUpdated);

        this.Dialog.hide(item);
      })
      .catch(console.log.bind(console));
  };
}
