import { copy } from 'angular';
import uuid from 'node-uuid';
import ChangePasswordController from 'pages/settings/controllers/changePasswordController';

export class SettingsController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (CrewMemberResource, Session, Dialog, Toast, API_URL) {
    this._CrewMemberResource = CrewMemberResource;
    this._Session = Session;
    this._Dialog = Dialog;
    this._Toast = Toast;
    this._API_URL = API_URL;
  }

  $onInit () {
    this.user = Object.assign({}, this._Session.user());
    this._CrewMemberResource.show(this.user.id, { include: 'group,profile.image' }).then((user) => {
      this.user = user;
    });
  }

  submit (user) {
    let isNewFormData = !this.form.$pristine;
    let isNewImage = !!this.uploadImage;

    if ((!isNewFormData && !isNewImage) || this.form.$invalid) {
      return;
    }

    user = copy(user);
    delete user.group;
    delete user.profession;

    if (isNewFormData) {
      this._CrewMemberResource.update(user.id, user, { include: 'group,profile.image' }).then((data) => {
        this.user = data;
        this._updateSessionUser(data);
        this._Toast.showSimple('Profile data updated.');
      });
    }

    if (isNewImage) {
      let imageId = uuid.v4();
      this.isUploading = true;
      this._CrewMemberResource.uploadProfileImage(this.uploadImage, user.id, {
        id: imageId,
        imageable_id: user.id,
        imageable_type: 'crew_member',
      }).then((data) => {
        this.isUploading = false;
        this.user.profile_image = data.profile_image;
        this._updateSessionUser(data);
        this._Toast.showSimple('Profile avatar updated.');
      }, (err) => {
        console.log(err);
      }, (value) => {
        this.profileImagePercentUploaded = (value.loaded / value.total) * 100;
      });
    }
  }

  showChangePasswordDialog (evt) {
    let dialogParams = {
      controller: ChangePasswordController,
      targetEvent: evt,
      templateUrl: 'pages/settings/templates/changePasswordDialog.tpl.html',
    };

    this._Dialog.show(dialogParams).then((item) => {
      this._Toast.showSimple('Password successfully changed.');
    }).catch((error) => {
      if (error) {
        this._Toast.showSimple('Error while chaning password.');
      }
    });
  }

  isAdmin () {
    const user = this._Session.user();
    return user.group.slug === 'system-administrators';
  }

  _updateSessionUser (data) {
    this._Session.setUser(data);
  }
}

export default {
  controller: SettingsController,
  templateUrl: 'pages/settings/templates/settings.tpl.html',
};
