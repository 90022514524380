import { element } from 'angular';

export class CalendarCellController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($element, $rootScope, $scope) {
    this._element = $element;
    this._rootScope = $rootScope;
    this._scope = $scope;
  }

  $onInit () {
    this._more = element(this._element[0].querySelectorAll('div.c-calendar-cell__more'));

    this._scope.$watch(() => {
      return this.day.events;
    }, (newVal) => {
      this._scope.$evalAsync(() => {
        this._events = element(this._element[0].querySelectorAll('div.c-calendar-cell__event'));
        if (this._more.css('display') === 'block') {
          this._more.css('display', 'none');
        }
        this._filterEventVisibility();
      });
    }, true);
  }

  $postLink () {
    this._rootScope.$on('WINDOW_RESIZE', () => {
      this._filterEventVisibility();
    });
  }

  isOneDayEvent (event) {
    return !event.all_day_event && !event.isMultiDayEvent;
  }

  isStartOfMultiDayEvent (event) {
    return !event.all_day_event && event.isMultiDayEvent && event.isStartDate(this.day);
  }

  getEventStyles (event) {
    let isMultiOrAllDayEvent = event.all_day_event || event.isMultiDayEvent;
    return {
      'color': isMultiOrAllDayEvent ? '#FFFFFF' : event.type.color,
      'background-color': isMultiOrAllDayEvent
          ? (event.type.color ? event.type.color : '#2D5744')
          : 'transparent',
    };
  }

  _filterEventVisibility () {
    if (!this.day.events.length || this.day.events.length < 3) {
      return;
    }

    let cellHeight = this._element.prop('offsetHeight');
    let requiredHeight = this._events.length * 20 + 12 + 24;  // event count * 20 + cell paddings + date field height
    let eventCount = this._events.length;

    if (requiredHeight < cellHeight) {
      if (this._more.css('display') === 'block') {
        this._more.css('display', 'none');
        this._events.css('display', 'block');
      }
      return;
    }

    let hideCount = Math.floor((requiredHeight - cellHeight) / 20) + 2;
    if (eventCount - hideCount < 1) {
      hideCount--;
    }

    this._events.css('display', 'block');
    for (let i = eventCount - 1, counter = 0; i >= 0; i--) {
      this._events[i].style.display = 'none';
      if (++counter === hideCount) {
        break;
      }
    }

    if (this._more.css('display') === 'none') {
      this._more.css('display', 'block');
    }
    this._more.text(`+${hideCount} more`);
  };
}

export default {
  bindings: {
    day: '<',
    onDaySelect: '&',
  },
  controller: CalendarCellController,
  templateUrl: 'pages/calendar/templates/calendarCell.tpl.html',
};
