/* global FormData */
import RestResource from 'common/resource/services/restResource';

export function parse (item) {
  let parsed = {
    id: item.id || null,
    email: item.email,
    first_name: item.first_name,
    last_name: item.last_name,
    phone_no: item.phone_no,
    mobile_no: item.mobile_no,
    registration_number: item.registration_number,
  };

  if (typeof item.user_active !== 'undefined' && item.user_active !== null) {
    parsed.user_active = item.user_active ? 1 : 0;
  } else {
    parsed.user_active = 1;
  }

  if (typeof item.profile_active !== 'undefined' && item.profile_active !== null) {
    parsed.profile_active = item.profile_active ? 1 : 0;
  } else {
    parsed.profile_active = 1;
  }

  if (item.password) {
    parsed.password = item.password;
  }

  if (item.group) {
    parsed.group_id = item.group.id;
  }

  if (item.profession) {
    parsed.profession_id = item.profession.id;
  }

  return parsed;
}

export function format (item) {
  let formatted = {
    id: item.id,
    email: item.email,
    user_active: !!item.active,
  };

  if (item.profile) {
    formatted.first_name = item.profile.data.first_name;
    formatted.last_name = item.profile.data.last_name;
    formatted.phone_no = item.profile.data.phone_no;
    formatted.mobile_no = item.profile.data.mobile_no;
    formatted.registration_number = item.profile.data.registration_number;
    formatted.profile_active = !!item.profile.data.active;

    if (item.profile.data.profession) {
      formatted.profession = item.profile.data.profession.data;
    }

    if (item.profile.data.image) {
      formatted.profile_image = item.profile.data.image.data;
    }
  }

  if (item.group) {
    formatted.group = item.group.data;
  }

  if (item.base) {
    formatted.base = item.base;
  }

  return formatted;
}

export default class CrewMemberResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'users';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }

  changePassword (passwords) {
    return this._http
      .post(`${this.apiUrl}/${this.resourcePath}/change-password`, passwords)
      .then(response => response.data.data);
  }

  uploadProfileImage (image, id, data) {
    let url = `${this.apiUrl}${this.resourcePath}/${id}/profile-image`;
    let deferred = this._q.defer();
    const requestData = Object.assign({}, { image: image }, data);
    this._http({
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': undefined,
      },
      uploadEventHandlers: {
        progress: function (e) {
          deferred.notify(e);
        },
      },
      data: requestData,
      transformRequest: function (data, headersGetter) {
        let formData = new FormData();
        formData.append('id', data.id);
        formData.append('image', data.image);
        formData.append('imageable_id', data.imageable_id);
        formData.append('imageable_type', data.imageable_type);
        return formData;
      },
    })
    .then(response => {
      deferred.resolve(format(response.data.data));
    })
    .catch(err => {
      deferred.reject(err);
    });

    return deferred.promise;
  }
}
