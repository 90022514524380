import { SECTIONS } from 'core/prf/services/laboratory-findings-dialog/laboratory-findings-dialog.controller';

export default {
  bindings: {
    isEditing: '<',
    onEditClick: '&',
    item: '<',
  },
  controller: function () {
    'ngInject';

    this.$onInit = () => {
      this.sections = [ ...SECTIONS ];
    };
  },
  templateUrl: 'core/prf/components/survey-laboratory-findings-details/survey-laboratory-findings-details.tpl.html',
};
