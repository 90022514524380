import { format as formatBodyPart } from 'core/categories/resources/body-part.resource';

export default function format (item) {
  const i = {
    id: item.id,
    notes: item.notes,
    status: item.status,
  };

  if (item.bodyPart) {
    i.body_part = formatBodyPart(item.bodyPart.data);
  }

  if (item.injury) {
    i.injury = item.injury.data;
  }

  if (item.injuryType) {
    i.injuryType = item.injuryType.data;
  }

  return i;
}
