import moment from 'moment';
import RestResource from 'common/resource/services/restResource';
import { format as formatInterval } from 'core/categories/resources/followup-interval.resource';
import { format as formatOutcome } from 'core/categories/resources/followup-outcome.resource';
import formatIllness from 'core/prf/services/followup-illness-formatter.service';
import formatInjury from 'core/prf/services/followup-injury-formatter.service';
import parseIllness from 'core/prf/services/followup-illness-parser.service';
import parseInjury from 'core/prf/services/followup-injury-parser.service';

export function format (item) {
  const i = {
    id: item.id,
    outcome_notes: item.outcome_notes,
    time: moment(item.time, 'YYYY-MM-DD HH:mm:ss').seconds(0).toDate(),
  };

  if (item.interval) {
    i.interval = formatInterval(item.interval.data);
  }

  if (item.outcome) {
    i.outcome = formatOutcome(item.outcome.data);
  }

  if (item.illnesses) {
    i.illnesses = item.illnesses.data.map(formatIllness);
  }

  if (item.injuries) {
    i.injuries = item.injuries.data.map(formatInjury);
  }

  return i;
}

export function parse (item) {
  const i = {
    id: item.id,
    patient_report_form_id: item.patient_report_form_id,
    interval_id: item.interval.id,
    outcome_id: item.outcome.id,
    outcome_notes: item.outcome_notes ? item.outcome_notes : null,
    time: item.time,
  };

  if (item.illnesses) {
    i.illnesses = item.illnesses.map(parseIllness);
  }

  if (item.injuries) {
    i.injuries = item.injuries.map(parseInjury);
  }

  return i;
}

export default class FollowupResource extends RestResource {
  init () {
    this.resourcePath = 'followups';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }
}
