import moment from 'moment';
import { DATE_TIME_FORMAT } from 'common/resource/module.constants';

export default function format (item) {
  const dateTime = moment(item.time, DATE_TIME_FORMAT).seconds(0).toDate();

  const i = {
    ...item,
    blood_pressure: (item.blood_pressure_1 !== null && item.blood_pressure_2 !== null)
      ? `${item.blood_pressure_1}/${item.blood_pressure_2}`
      : null,
    ie_ratio: (item.ie_ratio_1 !== null && item.ie_ratio_2 !== null)
      ? `${parseFloat(item.ie_ratio_1)}:${parseFloat(item.ie_ratio_2)}`
      : null,
    etco2: item.etco2 !== null ? parseFloat(item.etco2) : null,
    temp: item.temp !== null ? parseFloat(item.temp) : null,
    fio2: item.fio2 !== null ? parseFloat(item.fio2) : null,
    time: dateTime,
    date: dateTime,
  };

  delete item.blood_pressure_1;
  delete item.blood_pressure_2;
  delete item.ie_ratio_1;
  delete item.ie_ratio_2;

  return i;
};
