import uuid from 'node-uuid';

export default class CreateFollowupIntervalController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog, Toast) {
    this._Dialog = Dialog;
    this._Toast = Toast;
    this.ID = uuid.v4();

    this.includePath = 'pages/admin/categories/templates/forms/followup-interval.tpl.html';

    if (this.multiSelections) {
      this.selected = {};

      this.multiSelections.forEach((select) => {
        this.selected[select] = [];

        if (this.categoryData && this.categoryData[select]) {
          this.selected[select] = this.categoryData[select];
        }
      });
    }
  }

  submit (categoryData) {
    if (this.form.$invalid) {
      return;
    }

    if (this.multiSelections) {
      this.multiSelections.forEach((selection) => {
        categoryData[selection] = this.selected[selection];
      });
    }

    if (this.editing) {
      this._update(categoryData);
    } else {
      this._create(categoryData);
    }
  };

  cancel () {
    this._Dialog.cancel();
  };

  delete (id) {
    this._Dialog.confirm().then(() => {
      this.resource.destroy(id).then(() => {
        this._Dialog.cancel(id);
      });
    });
  };

  _create (categoryData) {
    categoryData.id = this.ID;

    this.resource.create(categoryData).then(() => {
      this._Dialog.hide(categoryData);
    }).catch(err => {
      if (err.status === 422 && err.data.errors.order) {
        this._Toast.showSimple(err.data.errors.order[0]);
      } else {
        console.log(err);
      }
    });
  };

  _update (categoryData) {
    this.resource.update(categoryData.id, categoryData, this.includeQuery).then((category) => {
      this._Dialog.hide(category);
    }).catch(err => {
      if (err.status === 422 && err.data.errors.order) {
        this._Toast.showSimple(err.data.errors.order[0]);
      } else {
        console.log(err);
      }
    });
  };
}
