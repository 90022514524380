import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class FollowupItemDialogController extends ResourceDialogController {
  init () {
    super.init();

    this.addAnother = false;

    if (!this.item) {
      this.item = {
        status: 'confirmed',
      };
    }
  }

  submit (item, addAnother) {
    if (!this.form) {
      throw new Error('Form not found.');
    }

    if (this.form.$invalid) {
      return;
    }

    if (!item.id) {
      item.id = uuid.v4();
    }
    return this.Dialog.hide({ item, addAnother });
  };

  delete (id) {
    this.Dialog.confirm().then(() => {
      this.Dialog.cancel(id);
    });
  }
}
