import RestResource from 'common/resource/services/restResource';

export function format (item) {
  const i = {
    id: item.id,
    name: item.name,
    measurement_type: item.measurement_type,
    dose: item.dose !== null ? parseFloat(item.dose) : null,
    volume: item.volume,
    min_rate: item.min_rate !== null ? parseFloat(item.min_rate) : null,
    max_rate: item.max_rate !== null ? parseFloat(item.max_rate) : null,
  };

  if (item.solution) {
    i.solution = item.solution.data;
  }

  if (item.routes) {
    i.routes = item.routes.data;
  }

  return i;
}

export function parse (item) {
  return {
    id: item.id,
    name: item.name,
    measurement_type: item.measurement_type ? item.measurement_type : null,
    dose: item.dose ? item.dose : null,
    volume: item.volume,
    solution_id: item.solution ? item.solution.id : null,
    min_rate: item.min_rate != null ? item.min_rate : null,
    max_rate: item.max_rate,
    routes: item.routes ? item.routes.map(i => i.id) : [],
  };
}

export default class InfusionResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'infusions';
  }

  getMeasurementTypes () {
    const url = this.apiUrl + '/categories/drug-measurement-types';
    return this._http.get(url).then(response => response.data.data);
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
