import CreateFeatureFlagController from './createFeatureFlagController';

export default function FeatureFlagResourceController (FeatureFlagResource) {
  'ngInject';

  this.singularName = 'Feature Flag';
  this.pluralName = 'Feature Flags';

  this.createDialogController = CreateFeatureFlagController;
  this.createDialogTemplateUrl = 'pages/admin/feature-flags/templates/createDialog.tpl.html';

  FeatureFlagResource
    .index()
    .then(items => {
      this.items = items.map(item => {
        return item;
      });
    });

  this.relationData = { };

  this.listFields = {
    label: 'Name',
  };

  this.usesSidenav = false;

  this.hideCreateDialog = true;
}
