import ResourceDialog from 'common/resource/services/resourceDialog';
import ReviewQpiDialogController from './review-qpi-dialog.controller';

export default class ReviewQpiDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(ReviewQpiDialogController);
    this.setTemplateUrl('core/prf/services/review-qpi-dialog/review-qpi-dialog.tpl.html');
  }
}
