import { FEATURE_FLAGS, FEATURE_FLAG_EVENTS } from 'pages/admin/feature-flags/module.constants';

export default class FeatureFlag {
  /**
   * @constructor
   * @ngInject
   */
  constructor (FeatureFlagResource, Utils, $rootScope) {
    this._rootScope = $rootScope;
    this._FeatureFlagResource = FeatureFlagResource;
    this._Utils = Utils;

    this._featureFlags = [];

    this.registerGetters();
    this.fetch();

    this._rootScope.$on(FEATURE_FLAG_EVENTS.featureFlagUpdated, this.fetch.bind(this));
  }

  // Register getters for each one of the Feature Flags constants
  // This way this service can be the only dependency to read Feature Flags throughout the app
  registerGetters () {
    for (const [key, value] of Object.entries(FEATURE_FLAGS)) {
      Object.defineProperty(this, key, {
        get: () => value,
      });
    }
  }

  fetch () {
    this._FeatureFlagResource.index().then(items => { this._featureFlags = items; });
  }

  isActive (featureFlag) {
    let result = this._Utils.findObjectInArrayByKey(this._featureFlags, featureFlag, 'name');

    if (result) {
      return result.value;
    }

    return false;
  }
}
