import ResourceDialog from 'common/resource/services/resourceDialog';
import FollowupInjuryDialogController from './followup-injury-dialog.controller';

export default class FollowupInjuryDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(FollowupInjuryDialogController);
    this.setTemplateUrl('core/prf/services/followup-injury-dialog/followup-injury-dialog.tpl.html');
  }
}
