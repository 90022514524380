import formatFailedRequirements from './failed-requirements-formatter.service';

export default function format (item) {
  let formatted = {
    id: item.id,
    status: item.status,
    indicator: {
      id: item.quality_indicator.data.id,
      name: item.quality_indicator.data.name,
    },
    failedRequirements: [],
  };

  if (item.status === 'fail' && item.failed_requirements) {
    formatted.failedRequirements = item.failed_requirements.data.map(formatFailedRequirements);
  }

  return formatted;
}
