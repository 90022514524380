export default class PrfPageService {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $q, BodyPartResource, DrugResource,
    PatientTypeResource, QualityIndicatorResource,
    PatientConsentResource, PRFResource,
    MedicationRouteResource, InfusionResource,
    InfusionSolutionResource, FollowupIntervalResource,
    FollowupOutcomeResource
  ) {
    this._q = $q;
    this._BodyPartResource = BodyPartResource;
    this._DrugResource = DrugResource;
    this._PatientTypeResource = PatientTypeResource;
    this._QualityIndicatorResource = QualityIndicatorResource;
    this._PatientConsentResource = PatientConsentResource;
    this._PRFResource = PRFResource;
    this._RouteResource = MedicationRouteResource;
    this._InfusionResource = InfusionResource;
    this._SolutionResource = InfusionSolutionResource;
    this._FollowupIntervalResource = FollowupIntervalResource;
    this._FollowupOutcomeResource = FollowupOutcomeResource;
  }

  getRelationData () {
    return this._q.all({
      bodyParts: this._BodyPartResource.index(),
      drugs: this._DrugResource.index(),
      infusions: this._InfusionResource.index(),
      patientTypes: this._PatientTypeResource.index(),
      qualityIndicators: this._QualityIndicatorResource.index(),
      routes: this._RouteResource.index(),
      solutions: this._SolutionResource.index(),
      measurementTypes: this._DrugResource.getMeasurementTypes(),
      patientConsents: this._PatientConsentResource.index({
        'archived': 0,
      }),
      followupIntervals: this._FollowupIntervalResource.index(),
      followupOutcomes: this._FollowupOutcomeResource.index(),
    }).catch(console.log.bind(console));
  }

  getPrf (id) {
    return this._PRFResource.show(id, {
      include: [
        'approval', 'incident', 'stockTransactions', 'images', 'attachments',
        'deployment', 'deployment.users', 'deployment.vehicleCallsign',
        'surveys', 'surveys.observations', 'surveys.prescribedDrugs', 'surveys.administeredDrugs',
        'surveys.assessedInjuries', 'surveys.assessedIllnesses', 'surveys.administeredInterventions',
        'surveys.laboratoryFindings', 'deployment.deploymentStatuses',
        'deployment.deploymentStatuses.deploymentStatus', 'deployment.deploymentStatuses.destination',
        'review', 'surveys.prescribedInfusions', 'surveys.administeredInfusions',
        'followups.interval', 'followups.outcome', 'followups.injuries.bodyPart', 'followups.injuries.injury',
        'followups.injuries.injuryType', 'followups.illnesses.bodyPart', 'followups.illnesses.illness',
        'followups.illnesses.illnessType',
      ],
    });
  }

  getTransportedDestination (deploymentStatuses) {
    const atHospital = deploymentStatuses.filter(item => !!item.deployment_status.is_at_hospital);
    if (atHospital.length) {
      const destination = atHospital[atHospital.length - 1].destination;
      return destination ? destination.name : '';
    }
    return null;
  }

  getPRFOutcome (transportedTo) {
    if (transportedTo !== '' && !transportedTo) {
      return 'Treated on scene';
    }

    let outcome = 'Transported to hospital';
    if (transportedTo !== '') {
      outcome += ' - ' + transportedTo;
    }
    return outcome;
  }

  getBreadcrumbRoutes (incidentId, deploymentId) {
    return [
      {
        title: 'Request Log',
        state: 'requestLog()',
      },
      {
        title: 'Incident',
        state: `incident({id: '${incidentId}'})`,
      },
      {
        title: 'Deployment',
        state: `deployments({id: '${deploymentId}'})`,
      },
      {
        title: 'PRF',
      },
    ];
  }
}
