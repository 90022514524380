export class FollowupSwitcherController {
  $onChanges (changes) {
    const { followups } = { ...changes };
    if (!followups) return;

    const { currentValue, previousValue } = { ...followups };
    if (!currentValue) return;

    if (this.selectedIndex == null && currentValue.length > 0) {
        // Initial load
      this.selectedIndex = 0;
      return;
    }

    if (!previousValue) return;

    if (currentValue.length > previousValue.length) {
        // New followup added
      this.selectedIndex = currentValue.length - 1;
      this.selectedFollowup = this.followups[this.selectedIndex];
    } else if (currentValue.length < previousValue.length) {
        // Followup deleted
      this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : null;
      this.selectedFollowup = this.followups[this.selectedIndex];
    } else if (currentValue.length === previousValue.length && this.selectedFollowup) {
      this.selectedIndex = this.followups.indexOf(this.selectedFollowup);
    }
  }

  select (index) {
    if (this.onSelect({ $event: { index } })) {
      this.selectedIndex = index;
      this.selectedFollowup = this.followups[index];
    }
  }

  add () {
    if (this.onAdd()) {
      this.selectedIndex = this.followups.length - 1;
    }
  }
  }

export default {
  bindings: {
    isEditing: '<',
    followups: '<',
    onSelect: '&',
    onAdd: '&',
  },
  controller: FollowupSwitcherController,
  templateUrl: 'core/prf/components/followup-switcher/followup-switcher.tpl.html',
};
