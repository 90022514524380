import formatReviewedIndicators from './reviewed-indicator-formatter.service';

export default function format (item) {
  let formatted = {
    id: item.id,
    reviewedIndicators: [],
  };

  if (item.reviewed_indicators) {
    formatted.reviewedIndicators = item.reviewed_indicators.data.map(formatReviewedIndicators);
  }

  return formatted;
}
