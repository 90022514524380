import RestResource from 'common/resource/services/restResource';

export function format (item) {
  return {
    id: item.id,
    name: item.name,
    order: item.order,
  };
}

export default class FollowupIntervalResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'categories/followup-intervals';
  }

  format (item) {
    return format(item);
  }
};
