import RestResource from 'common/resource/services/restResource';

export function format (item) {
  let formatted = {
    id: item.id,
    name: item.name,
    label: item.label,
    value: !!item.value,
  };

  return formatted;
}

export default class FeatureFlagResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'feature-flags';
  }

  format (item) {
    return format(item);
  }
}
