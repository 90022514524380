class ReviewQpiTabController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (ReviewQpiDialog, PrfPageService, Toast) {
    this.ReviewQpiDialog = ReviewQpiDialog;
    this.PrfPageService = PrfPageService;
    this.Toast = Toast;
  }

  showReviewDialog ($event, review) {
    this.ReviewQpiDialog
      .show({
        editing: true,
        $event,
        item: review,
        relationData: { qualityIndicators: this.relationData.qualityIndicators },
        locals: { prfId: this.prf.id },
      })
      .then(review => {
        this.prf.review = review;
        this.Toast.showSimple('PRF reviewed.');

        if (typeof this.onPrfUpdate === 'function') {
          this.onPrfUpdate({ prf: this.prf });
        }
      });
  }

  getReviewedIndicator (id) {
    if (!this.prf.review) {
      return null;
    }

    const i = this.prf.review.reviewedIndicators.findIndex((item) => {
      return item.indicator.id === id;
    });

    if (i === -1) {
      return null;
    }

    return this.prf.review.reviewedIndicators[i];
  }

  isIndicatorReviewed (id) {
    return this.getReviewedIndicator(id) !== null;
  }

  getReviewedIndicatorStatus (id) {
    const indicator = this.getReviewedIndicator(id);

    return indicator !== null ? indicator.status : null;
  }

  getFailedRequirements (id) {
    const indicator = this.getReviewedIndicator(id);

    return indicator !== null ? indicator.failedRequirements : null;
  }
}

export default {
  controller: ReviewQpiTabController,
  templateUrl: 'pages/requestLog/components/review-qpi-tab/review-qpi-tab.tpl.html',
  bindings: {
    isEditing: '<',
    prf: '<',
    relationData: '<',
    onPrfUpdate: '&',
  },
};
