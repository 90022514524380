import moment from 'moment';
import { DATE_TIME_FORMAT } from 'common/resource/module.constants';

export default function parse (item) {
  return Object.keys(item).reduce((acc, cur) => {
    if (cur === 'time') {
      acc[cur] = moment(item[cur]).format(DATE_TIME_FORMAT);
    } else if (item[cur] != null && !isNaN(item[cur])) {
      acc[cur] = parseFloat(item[cur]).toString();
    } else {
      acc[cur] = item[cur];
    }

    return acc;
  }, {});
};
