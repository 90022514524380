import uuid from 'node-uuid';

export default class CreateArchivableCategoryController {
  constructor (Utils, Dialog) {
    'ngInject';
    this._Utils = Utils;
    this._Dialog = Dialog;

    this.ID = uuid.v4();

    this.includePath = 'pages/admin/categories/templates/forms/archivable-category.tpl.html';

    if (!this.categoryData) {
      this.categoryData = {};
    }

    if (!this.categoryData.archived) {
      this.categoryData.archived = false;
    }
  }

  submit (categoryData) {
    if (this.form.$invalid) {
      return;
    }

    if (this.editing) {
      this._update(categoryData);
    } else {
      this._create(categoryData);
    }
  };

  cancel () {
    this._Dialog.cancel();
  };

  delete (id) {
    this._Dialog.confirm().then(() => {
      this.resource.destroy(id).then(() => {
        this._Dialog.cancel(id);
      });
    });
  };

  _create (categoryData) {
    categoryData.id = this.ID;

    this.resource.create(categoryData).then(() => {
      this._Dialog.hide(categoryData);
    }).catch(console.log.bind(console));
  };

  _update (categoryData) {
    this.resource.update(categoryData.id, categoryData).then((category) => {
      this._Dialog.hide(category);
    }).catch(console.log.bind(console));
  };
}
