import angular from 'angular';

import AbortReasonResource from './resources/abort-reason.resource';
import AmbulanceServiceResource from './resources/ambulance-service.resource';
import BodyPartResource from './resources/body-part.resource';
import CalendarEventTypeResource from './resources/calendar-event-type.resource';
import ConsumableTypeResource from './resources/consumable-type.resource';
import DeploymentReasonResource from './resources/deployment-reason.resource';
import DeploymentStatusResource from './resources/deployment-status.resource';
import HospitalSpecialtyResource from './resources/hospital-specialty.resource';
import IllnessResource from './resources/illness.resource';
import InjuryResource from './resources/injury.resource';
import InterventionResource from './resources/intervention.resource';
import IncidentTypeResource from './resources/incident-type.resource';
import LocationResource from './resources/location.resource';
import LocationTypeResource from './resources/location-type.resource';
import OfflineReasonResource from './resources/offile-reason.resource';
import PatientTypeResource from './resources/patient-type.resource';
import ProfessionResource from './resources/profession.resource';
import RefusalReasonResource from './resources/refusal-reason.resource';
import ServiceAreaResource from './resources/service-area.resource';
import StockLocationResource from './resources/stock-location.resource';
import SupplierResource from './resources/supplier.resource';
import PatientConsentResource from './resources/patient-consent.resource';
import MedicationRouteResource from './resources/medication-route.resource';
import InfusionSolutionResource from './resources/infusion-solution.resource';
import FollowupIntervalResource from './resources/followup-interval.resource';
import FollowupOutcomeResource from './resources/followup-outcome.resource';

const module = angular
  .module('gnaas.core.categories', [])
  .service('AbortReasonResource', AbortReasonResource)
  .service('AmbulanceServiceResource', AmbulanceServiceResource)
  .service('BodyPartResource', BodyPartResource)
  .service('CalendarEventTypeResource', CalendarEventTypeResource)
  .service('ConsumableTypeResource', ConsumableTypeResource)
  .service('DeploymentReasonResource', DeploymentReasonResource)
  .service('DeploymentStatusResource', DeploymentStatusResource)
  .service('HospitalSpecialtyResource', HospitalSpecialtyResource)
  .service('IllnessResource', IllnessResource)
  .service('InjuryResource', InjuryResource)
  .service('InterventionResource', InterventionResource)
  .service('IncidentTypeResource', IncidentTypeResource)
  .service('LocationResource', LocationResource)
  .service('LocationTypeResource', LocationTypeResource)
  .service('OfflineReasonResource', OfflineReasonResource)
  .service('PatientTypeResource', PatientTypeResource)
  .service('ProfessionResource', ProfessionResource)
  .service('RefusalReasonResource', RefusalReasonResource)
  .service('ServiceAreaResource', ServiceAreaResource)
  .service('StockLocationResource', StockLocationResource)
  .service('SupplierResource', SupplierResource)
  .service('PatientConsentResource', PatientConsentResource)
  .service('MedicationRouteResource', MedicationRouteResource)
  .service('InfusionSolutionResource', InfusionSolutionResource)
  .service('FollowupIntervalResource', FollowupIntervalResource)
  .service('FollowupOutcomeResource', FollowupOutcomeResource);

export default module.name;
