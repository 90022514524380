import {RESOURCE_ITEM_FIELD_TYPES} from 'common/resource/module.constants';

export default {
  bindings: {
    fields: '<',
    item: '<',
    onClick: '&',
    hideEditButton: '<',
  },
  controller: function ($mdSidenav) {
    'ngInject';

    this.profileFieldTypeItem = RESOURCE_ITEM_FIELD_TYPES.item;
    this.profileFieldTypeCollection = RESOURCE_ITEM_FIELD_TYPES.collection;
    this.profileFieldTypeRadio = RESOURCE_ITEM_FIELD_TYPES.radio;

    this.hideEditButton = this.hideEditButton || false;

    this.close = function () {
      $mdSidenav('resource-item').close();
    };

    this.edit = function (event, item) {
      if (typeof this.onClick !== 'function') {
        return;
      }
      this.onClick({event: event, item: item});
    };

    this.getProperty = function (item, key) {
      if (!item) {
        return;
      }

      if (key.indexOf('.') === -1) {
        return item[key];
      }

      try {
        return key.split('.').reduce((acc, part) => {
          if (!acc.hasOwnProperty(part)) {
            throw new Error();
          }

          return acc[part];
        }, item);
      } catch (e) {
        return null;
      }
    };
  },
  templateUrl: 'common/resource/templates/resourceItem.tpl.html',
};
