import ForgottenPasswordDialog from 'pages/login/controllers/forgotten-password-dialog.controller';

export class LoginController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($state, AuthResource, CrewMemberResource, JWT, IdleCheck, Session, Toast, Dialog, APP_ORG) {
    this._state = $state;
    this._AuthResource = AuthResource;
    this._CrewMemberResource = CrewMemberResource;
    this._JWT = JWT;
    this._IdleCheck = IdleCheck;
    this._Session = Session;
    this._Toast = Toast;
    this._Dialog = Dialog;
    this.APP_ORG = APP_ORG;
  }

  login (email, password) {
    if (this.form.$invalid) {
      return;
    }

    this._AuthResource.login(email, password).then((data) => {
      this._JWT.set(data.meta.token);
      this._Session.start(this._CrewMemberResource.format(data.data));
      this._IdleCheck.start();

      if (this._Session.user().group.slug === 'charity-staff') {
        this._state.go('calendar');
        return;
      }

      if (this._state.params.previousState) {
        this._state.go(this._state.params.previousState.name, this._state.params.previousState.params);
      } else {
        this._state.go('dashboard');
      }
    }).catch(() => {
      this._Toast.showSimple('Invalid username/password!');
    });
  }

  showPasswordResetDialog ($event) {
    const dialogParams = {
      controller: ForgottenPasswordDialog,
      item: {
        email: this.credentials ? this.credentials.email : '',
      },
      targetEvent: $event,
      templateUrl: 'pages/login/templates/forgotten-password.tpl.html',
    };

    this._Dialog.show(dialogParams).then(item => this._Toast.showSimple('Password reset email sent.'));
  }
}

export default {
  controller: LoginController,
  templateUrl: 'pages/login/templates/login.tpl.html',
};
