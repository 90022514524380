import { format as formatCrewMember } from 'pages/crewMembers/services/crewMemberResource';
import formatPrescription from './prescribed-infusion-formatter.service';
import { toDateTimeObject } from 'common/utils/services/dateTime';

export default function format (item) {
  return {
    id: item.id,
    prescription: item.prescription ? formatPrescription(item.prescription.data) : null,
    administered_by: formatCrewMember(item.administeredBy.data),
    details: item.details.data.map(detail => {
      const dateTime = toDateTimeObject(detail.time);

      return {
        id: detail.id,
        time: dateTime,
        date: dateTime,
        rate: parseFloat(detail.rate),
      };
    }),
  };
}
