import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from 'common/resource/module.constants';

export default function parse (item) {
  const dateStr = `${moment(item.date).format(DATE_FORMAT)} ${moment(item.time).format(TIME_FORMAT)}`;

  const parseInteger = (value = null) => {
    return value !== null ? parseInt(value, 10) : null;
  };

  const parseAndSplit = (separator, value = null) => {
    return value !== null ? value.split(separator) : [null, null];
  };

  const i = {
    ...item,
    time: dateStr,
    pupil_size_left: parseInteger(item.pupil_size_left),
    pupil_size_right: parseInteger(item.pupil_size_right),
    blood_pressure_1: parseAndSplit('/', item.blood_pressure)[0],
    blood_pressure_2: parseAndSplit('/', item.blood_pressure)[1],
    ie_ratio_1: parseAndSplit(':', item.ie_ratio)[0],
    ie_ratio_2: parseAndSplit(':', item.ie_ratio)[1],
    gcs: item.gcs || null,
  };

  delete i.blood_pressure;
  delete i.ie_ratio;

  return i;
};
