import PRFRejectionDialogController from './prf-rejection-dialog.controller';

export default class TransactionRejectionDialog {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog) {
    this._Dialog = Dialog;
    this._dialogOptions = {
      controller: PRFRejectionDialogController,
      templateUrl: 'core/prf/services/prf-rejection-dialog/prf-rejection-dialog.tpl.html',
    };
  }

  show ({ $event, data }) {
    const dialogParams = Object.assign({}, this._dialogOptions, {
      targetEvent: $event || null,
      multiple: true,
      locals: { data },
    });
    return this._Dialog.show(dialogParams);
  }
}
