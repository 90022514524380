class AdministeredDrugsController {
  $onInit () {
    this.clearSearch();
  }

  initSearch (index) {
    this.openIndex = index;
  }

  clearSearch () {
    this.openIndex = null;
    this.searchTerm = '';
  }

  filterOptions (index) {
    if (this.openIndex === index) {
      return this.searchTerm;
    }
  }

  getClasses (name, index) {
    return {
      'has-errors': this.administeredDrugsForm[`${name}_${index}`].invalid,
      't-no-padding': ~['drug', 'prescription', 'date'].indexOf(name),
    };
  }

  getSelectedName (prescription) {
    return prescription && prescription.drug ? prescription.drug.name : '-';
  }

  setDose (administeredDrug) {
    if (!administeredDrug || !administeredDrug.prescription) {
      return;
    }

    administeredDrug.dose = administeredDrug.prescription.dose;
  }
}

export default {
  bindings: {
    isEditing: '<',
    viewAllSurveys: '<',
    surveys: '<',
    prescriptions: '<',
    onAddClick: '&',
    onRemoveClick: '&',
  },
  controller: AdministeredDrugsController,
  templateUrl: 'core/prf/components/administered-drugs/administered-drugs.tpl.html',
};
